import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivacyPolicyComponent from "./pages/PrivacyPolicy";
import AboutUsComponent from "./pages/AboutUs";
import HeaderComponent from "./components/Header";
import AirPortShuttleComponent from "./pages/AirportShuttle";
import EmergencyComponent from "./pages/EmergencyServices";
import JoyRideBookingComponent from "./pages/JoyRideBooking";
import JoyRideComponent from "./pages/JoyRide";
import PrivateChartersComponent from "./components/PrivateCharters";
import TermsComponent from "./components/Terms";
import HomeComponent from "./pages/Home";
import ChardhamByHelicopter from "./pages/ChardhamByHelicopter";
// import PackageDetailPage from "./pages/PackageDetail";
import "./components/App.css";
import "./components/blaze.css";
import "./components/pure.css";
import "./components/grids-responsive-min.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <HeaderComponent corporate={false} />
        <Switch>
          <Route path="/" exact component={HomeComponent} />
          <Route
            path="/joyride-booking"
            exact
            component={JoyRideBookingComponent}
          />
          <Route
            path="/airport-shuttle"
            exact
            component={AirPortShuttleComponent}
          />
          <Route
            path="/emergency-services"
            exact
            component={EmergencyComponent}
          />
          <Route path="/bookwithus" exact component={JoyRideComponent} />
          <Route
            path="/private-charters"
            exact
            component={PrivateChartersComponent}
          />
          <Route
            path="/privacy-policy"
            exact
            component={PrivacyPolicyComponent}
          />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsComponent}
          />
          <Route
            path="/chardham-by-helicopter"
            exact
            component={ChardhamByHelicopter}
          />
          <Route path="/about-us" exact component={AboutUsComponent} />
          <Redirect from="/joy-rides" to="/bookwithus" />
          {/* <Route
          path="/packages/:packageId"
          exact
          component={PackageDetailPage}
        /> */}
        </Switch>
      </div>
    );
  }
}

export default App;
