import React, { Component } from "react";
import HeaderComponent from "../../components/Header";

import "./AboutUs.css";

class AboutUsComponent extends Component {
  render() {
    return (
      <div>
        <div className="privacy-section">
          <h2>About US</h2>
          <p>
            Thumby Aviation is known for flying helicopters & fixed wing
            aircrafts for non scheduled air transport operations. We, at Thumby
            Aviation are passionate about the aviation industry and strive to be
            the front runners for redefining aviation in the Indian
            Subcontinent. Led by Gp Capt. KNG Nair, an Indian Air force Veteran
            with a rich experience in aviation, and a fleet of experienced
            pilots from the Indian Air Force and Indian Army, we boast of a zero
            incident track record.
          </p>
          {/* <h3>WHAT PERSONAL INFORMATION DOES THUMBY AVIATION COLLECT?</h3>
          <p>We collect the following types of information from our users:</p> */}
          <ul className="policy-points">
            <li>40+ Years in Aviation & 20+ Years in Aviation Consulting</li>
            <li>Capabilities to fly in difficult & restricted terrains</li>
            <li>Rich experience in working with Government Bodies</li>
            <li>
              Stellar roaster of experienced & passionate pilots & engineers
            </li>
          </ul>
        </div>
        <footer id="footer" className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 conditions" align="center">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <a href="/#top">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  {/* <li>
                    <a href="">FAQ's</a>
                  </li> */}
                  <li>
                    <a href="https://thumbyaviation.com/#CONTACT">Contact Us</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">T&amp;C</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
              <div
                className="col-lg-12"
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  fontSize: "18px",
                }}
              >
                <b>© 2018 Thumby Aviation Pvt. Ltd - All Rights Reserved</b>
                <br />
                <p style={{ lineHeight: "30px", fontSize: "16px" }}>
                  Call : +91-9400399999
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default AboutUsComponent;
