import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
// import { initializeApp } from "firebase/app";
// import { RecaptchaVerifier, getAuth } from "firebase/auth";
// import "firebase/auth";

// import config from "./config";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  tabs: {
    backgroundColor: "#ffffff",
    textColor: "#000000",
    selectedTextColor: "#000000",
  },
});

// initializeApp(config.firebaseConfig);

// export const verify = new RecaptchaVerifier(
//   "recaptcha-verifier",
//   {
//     size: "invisible",
//   },
//   getAuth()
// );

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider muiTheme={muiTheme}>
      <App />
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
