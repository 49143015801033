import React, { Component } from "react";
import "./joyride.css";
import axios from "axios";
import config from "../../config";
import LoadingScreen from "react-loading-screen";
import MapIcon from "material-ui/svg-icons/maps/place";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import SelectField from "material-ui/SelectField";
import MenuItem1 from "material-ui/MenuItem";
import FooterComponent from "../../components/FooterComponent";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import uniq from "lodash/uniq";
import moment from "moment";
import Checkbox from "material-ui/Checkbox";

const getSlotDescription = (schedule) => {
  if (schedule.description) {
    return schedule.description;
  }

  if (schedule?.arrivalTime?.length) {
    return `Slot timings are from ${moment(
      schedule.arrivalTime[0],
      "HH:mm"
    ).format("hh:mm a")}${
      schedule.arrivalTime[1]
        ? ` to ${moment(schedule.arrivalTime[1], "HH:mm").format("hh:mm a")}`
        : ""
    }`;
  }

  return "";
};

class JoyRideComponent extends Component {
  state = {
    schedules: [],
    paths: [],
    loading: false,
    localLoader: [],
    selected: {
      schedule: [],
      date: [],
    },
    error: [],
    payableAmount: [],
    availableSeats: [],
    termsnConditions: [],
    tncChecks: [],
  };

  getJoyRideSchedules = () => {
    this.setState({ loading: true });
    axios
      .get(config.baseUrl + "v2/schedules/joyrides", {
        headers: {
          "x-helitaxii-client": "browser",
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          this.setState({ schedules: res.data.schedules });
          this.orderByPaths(res.data.schedules);

          console.log(res.data.schedules);
        } else {
          console.log("Something went wrong");
        }
      });
  };

  orderByPaths = (schedules) => {
    let { paths } = this.state;
    schedules.forEach((schedule) => {
      const pathIndex = paths.findIndex((p) => p._id === schedule.path._id);
      if (pathIndex === -1) {
        paths.push({
          ...schedule.path,
          schedules: [schedule],
          startDate: schedule.effectiveFromDate,
          endDate: schedule.effectiveToDate,
        });
      } else {
        if (
          new Date(schedule.effectiveFromDate) <
          new Date(paths[pathIndex].startDate)
        ) {
          paths[pathIndex].startDate = schedule.effectiveFromDate;
        }

        if (
          new Date(schedule.effectiveToDate) >
          new Date(paths[pathIndex].endDate)
        ) {
          paths[pathIndex].endDate = schedule.effectiveToDate;
        }

        paths[pathIndex].schedules.push(schedule);
      }
    });
    this.setState(paths);
  };

  handleScheduleChange(index, event, n, scheduleId) {
    const { selected, schedules } = this.state;

    let {
      selected: { schedule },
      localLoader,
    } = this.state;

    const scheduleIndex = schedules.findIndex((s) => s._id === scheduleId);
    schedule[index] = schedules[scheduleIndex];
    localLoader[index] = 1;
    this.setState(
      {
        selected: {
          ...selected,
          schedule,
        },
        localLoader,
      },
      () => this.checkSeatAvailability(index)
    );
  }

  handleDateChange(index, selectedDate) {
    let {
      selected,
      selected: { date, schedule },
      localLoader,
      availableSeats,
      payableAmount,
    } = this.state;

    date[index] = selectedDate;
    schedule[index] = null;
    availableSeats[index] = null;
    payableAmount[index] = null;

    this.setState({
      selected: {
        ...selected,
        date,
        schedule,
      },
      localLoader,
    });
  }

  handleBookAction = (index) => {
    const valid = this.isFormValid(index);

    if (!valid) {
      return;
    }

    if (!this.state.tncChecks[index]) {
      const { error } = this.state;
      error[index] = "Please agree to the terms and conditions.";
      this.setState({
        error,
      });
      return;
    }

    const { availableSeats } = this.state;
    if (valid && availableSeats[index] > 0) {
      const {
        selected: { date, schedule },
      } = this.state;
      const travelDate = moment(date[index]).format("MM/DD/YYYY");

      this.props.history.push({
        pathname: "/joyride-booking",
        state: {
          selectedScheduleObj: schedule[index],
          date: travelDate,
          selectedDateFormatted: travelDate,
          seats: availableSeats[index],
        },
      });
    }
  };

  isFormValid = (index) => {
    let formValid = true;
    const {
      selected: { date, schedule },
    } = this.state;
    let { error } = this.state;

    if (!date[index]) {
      formValid = false;
      error[index] = "Please select date.";
      this.setState({
        error,
      });
      return formValid;
    }

    if (!schedule[index] || !schedule[index]._id) {
      formValid = false;
      error[index] = "Please select a time slot.";
      this.setState({
        error,
      });

      return formValid;
    }

    if (formValid) {
      error[index] = "";
      this.setState({ error });
    }
    return formValid;
  };

  checkSeatAvailability = (index) => {
    const valid = this.isFormValid(index);

    if (valid) {
      this.setState({ tncChecks: [] });
      const {
        selected: { schedule, date },
      } = this.state;

      const travelDate = moment(date[index]).format("MM/DD/YYYY");
      axios
        .get(
          `${config.baseUrl}v2/schedules/joyrides/availability?travelDate=${travelDate}&scheduleId=${schedule[index]._id}`
        )
        .then((res) => {
          let { localLoader } = this.state;
          localLoader[index] = 0;
          this.setState({ localLoader });
          if (res.status === 200) {
            let { availableSeats, payableAmount } = this.state;
            availableSeats[index] = res.data.availableSeats;
            payableAmount[index] = res.data?.priceBreakup?.grandTotal;

            this.setState({
              availableSeats,
              payableAmount,
            });
          } else {
            console.log("Something went wrong");
          }
        });
    } else {
      let { localLoader } = this.state;
      localLoader[index] = 0;
      this.setState({ localLoader });
    }
  };

  componentDidMount() {
    this.getJoyRideSchedules();
  }

  validateEmail(email) {
    var regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(String(email).toLowerCase());
  }

  formatTime = (time) => {
    const [hours, minutes] = time.split(":");

    if (parseInt(hours, 10) > 12) {
      return `${parseInt(hours, 10) - 12}:${minutes} pm`;
    } else {
      return `${hours}:${minutes} am`;
    }
  };

  onChangeTncCheck = (index, event) => {
    let { tncChecks } = this.state;
    const isChecked = event.target.checked;
    tncChecks[index] = isChecked;
    this.setState({ tncChecks }, () => this.isFormValid(index));
  };

  render() {
    if (this.state.loading) {
      return (
        <LoadingScreen
          loading={this.state.loading}
          bgColor="#005299"
          spinnerColor="#9ee5f8"
          textColor="#f7ca18"
          logoSrc="/assets/icon.png"
          text="Please Wait ..."
        >
          <div />
        </LoadingScreen>
      );
    }

    const {
      paths,
      selected,
      localLoader,
      availableSeats,
      payableAmount,
      error,
      tncChecks,
    } = this.state;

    console.log("SELECTED STATE", this.state);

    return (
      <section
        id="joy-ride-page"
        style={{
          paddingTop: "60px",
          minHeight: "100vh",
          background: "#f2f2f2",
        }}
      >
        <Dialog
          title="T&C and Cancellation Policy"
          autoScrollBodyContent={true}
          open={!!this.state.termsnConditions.length}
          onRequestClose={() => this.setState({ termsnConditions: [] })}
        >
          <div style={{ paddingTop: "10px" }}>
            {this.state.termsnConditions.map((t, index) => (
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${index + 1}. ${t}`,
                  }}
                />
              </div>
            ))}
            <div>
              <p>
                {this.state.termsnConditions.length + 1}. In case of any query
                please contact at  info@helitaxii.com or 9400399999 / 9632515123
              </p>
            </div>
            <div>
              <p>
                {this.state.termsnConditions.length + 2}. After booking your
                tickets will be shared via email withing 30 minutes of the
                payment confirmation. If not received after 30 minutes of
                payment, please check your spam folder or email at
                Info@helitaxii.com
              </p>
            </div>
          </div>
        </Dialog>
        <div className="banner joride" style={{ height: "400px" }}>
          <h1
            style={{
              margin: 0,
              textAlign: "center",
              fontSize: "50px",
              fontWeight: 600,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              fontFamily: "Open Sans",
            }}
          >
            Fly with Thumby Aviation
          </h1>
        </div>
        <div className="schedules-wrapper" style={{ padding: "20px" }}>
          {paths.map((path, index) => (
            <div
              className="path-wrapper"
              key={path._id}
              style={{
                position: "relative",
                border: "1px solid #ccc",
                boxShadow: "2px 2px 5px rgba(0,0,0,0.26)",
                background: "white",
                display: "flex",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "#ccc",
                  zIndex: 2,
                  opacity: 0.5,
                  display: localLoader[index] ? "block" : "none",
                }}
              >
                <div className="lds-roller">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <div
                className="details"
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  height: "100%",
                }}
              >
                <h3
                  style={{
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    color: "#005299",
                  }}
                >
                  {path.name}
                </h3>
                <div>
                  <a
                    href={path.locationLink}
                    target="blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MapIcon style={{ height: "14px", width: "14px" }} />{" "}
                    <span>Show Helipad on Map</span>
                  </a>
                </div>
                <h4
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <b style={{ fontSize: "18px" }}>
                    {moment(path.startDate).format("Do MMM YYYY")} -{" "}
                    {moment(path.endDate).format("Do MMM YYYY")}
                  </b>
                </h4>
                <h5
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: path.description,
                  }}
                ></h5>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                  }}
                >
                  <FlatButton
                    label="T&C and Cancellation Policy"
                    disableTouchRipple
                    onClick={() =>
                      this.setState({
                        termsnConditions: uniq(
                          path.schedules
                            .map((schedule) => schedule.ticketNotes || [])
                            .flat()
                        ),
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="seat-availability"
                style={{
                  width: "60%",
                  display: "inline-block",
                  padding: "10px",
                  paddingLeft: "20px",
                  verticalAlign: "middle",
                  position: "relative",
                }}
              >
                <div className="form-wrapper">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      margin="normal"
                      label="Travel Date"
                      onChange={this.handleDateChange.bind(this, index)}
                      style={{
                        height: "56px",
                        margin: 0,
                        marginRight: "10px",
                        cursor: "pointer",
                        verticalAlign: "text-bottom",
                      }}
                      value={selected.date[index] ? selected.date[index] : null}
                      maxDate={new Date(path.endDate)}
                      minDate={
                        new Date(path.startDate) >= new Date()
                          ? new Date(path.startDate)
                          : new Date()
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <SelectField
                    floatingLabelText="Time Slot"
                    className="time-slot-select"
                    value={
                      selected.schedule[index]
                        ? selected.schedule[index]._id
                        : 0
                    }
                    disabled={selected.date[index] ? false : true}
                    style={{
                      border: "none",
                      width: "300px",
                    }}
                    onChange={this.handleScheduleChange.bind(this, index)}
                  >
                    <MenuItem1
                      value={0}
                      primaryText={"Select a Time Slot"}
                      disabled={true}
                    />
                    {path.schedules
                      .filter((s) => {
                        return (
                          selected.date[index] &&
                          moment(selected.date[index])
                            .set({
                              hour: 0,
                              milliseconds: 0,
                              minute: 0,
                              second: 0,
                            })
                            .diff(moment(s.effectiveFromDate)) >= 0 &&
                          moment(selected.date[index])
                            .set({
                              hour: 0,
                              milliseconds: 0,
                              minute: 0,
                              second: 0,
                            })
                            .diff(moment(s.effectiveToDate)) <= 0
                        );
                      })
                      .map((s) => (
                        <MenuItem1
                          key={s._id}
                          value={s._id}
                          primaryText={s.name}
                        />
                      ))}
                  </SelectField>
                </div>
                {selected.schedule[index] && (
                  <div>{getSlotDescription(selected.schedule[index])}</div>
                )}
                <div>
                  {selected.schedule[index] &&
                  availableSeats[index] === 0 &&
                  !error[index] ? (
                    <div
                      className="available-seats"
                      style={{
                        display: "inline-block",
                        padding: "5px",
                        paddingLeft: 0,
                        paddingRight: "15px",
                        color: "black",
                      }}
                    >
                      <b>
                        <span
                          style={{
                            fontSize: "12px",
                            padding: "4px 4px 4px 0",
                            color: "red",
                            fontWeight: "600",
                            minHeight: "20px",
                          }}
                        >
                          {"No seats available"}
                        </span>
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="payable-amount"
                    style={{
                      display: "inline-block",
                      padding: "5px 0",
                      fontSize: "18px",
                    }}
                  >
                    {payableAmount[index] && availableSeats[index] > 0 ? (
                      <b style={{ fontSize: "16px" }}>
                        Payable amount :{" "}
                        <span style={{ color: "#e2b148", fontSize: "16px" }}>
                          {payableAmount[index]}
                        </span>
                      </b>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <Checkbox
                      color="secondary"
                      onClick={(e) => this.onChangeTncCheck(index, e)}
                      checked={tncChecks[index]}
                      label={
                        "I confirm that I have read and agree to the cancellation terms and the terms and conditions."
                      }
                    />
                  </div>
                  {error[index] && (
                    <div
                      style={{
                        fontSize: "12px",
                        padding: "4px 4px 4px 0",
                        color: "red",
                        fontWeight: "600",
                        minHeight: "20px",
                      }}
                    >
                      {error[index]}
                    </div>
                  )}
                  <div>
                    <button
                      className="book-btn"
                      disabled={availableSeats[index] === 0}
                      style={{
                        border: "2px solid #005299",
                        background: "#005299",
                        color: "white",
                        boxShadow: "none",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                      onClick={() => this.handleBookAction(index)}
                    >
                      Book
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <FooterComponent />
      </section>
    );
  }
}
export default JoyRideComponent;
