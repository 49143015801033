import React, { Component } from "react";
import "../../components/Home.css";
class EmergencyComponent extends Component {
  render() {
    return (
      <div>
        <section id="emergency-services" className="home-jumbotron">
          <h3>emergerncy services</h3>
          <div className="emergency-wrapper bookings-wrapper">
            <p>We make every second count as we fly to save lives</p>
            <p className="description">
              Helicopter emergency medical services are an integral part in many
              health care systems in the developed world. It helps in bringing
              advanced medical care and medical competence to the scene,
              shortening the transport time, providing access to remote areas,
              and reducing the time elapsed until definitive care is available.
              Now you can avail word-class emergency services during medical/
              disaster situations using Thumby Aviaiton’s helitaxii service.
              <br />
              <br />
              Call us on +91-9400399999
              <br /> and we will send help your way!
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default EmergencyComponent;
