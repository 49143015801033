import React, { Component } from "react";
import "./Home.css";
class PrivateChartersComponent extends Component {
  render() {
    return (
      <div>
          <section id="private-charters" className="home-jumbotron">
          <h3>Private Charters</h3>
          <div className="emergency-wrapper bookings-wrapper">
            <p>
              Get your own armchair in the sky. Travel in style - anywhere,
              anytime!
            </p>
            <p className="description">
              Charter a helicopter exclusively and tackle strict schedules or
              complete multiple site visits in one day. Fly in style, safety and
              comfort. Planning a trip with you friends or family to a weekend
              getaway but don’t want waste time in traffic? We are here to help
              you out! <br />
              <br />
              For further queries <br />
              <i class="fas fa-phone-square" />
              &nbsp;&nbsp;+91-9400399999
              <br />
              <i class="fas fa-envelope" />
              &nbsp;&nbsp;info@helitaxii.com
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivateChartersComponent;
