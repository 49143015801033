import React, { Component } from "react";
import HeaderComponent from "../../components/Header";

import "./privacyPolicy.css";

class PrivacyPolicyComponent extends Component {
  render() {
    return (
      <div>
        <div className="privacy-section">
          <h2>Application Privacy Statement</h2>
          <p>
            This privacy statement (“Privacy Statement”) applies to the
            treatment of personally identifiable information submitted by, or
            otherwise obtained from, you in connection with the associated
            application (“HeliTaxii”). The Application is provided by THUMBY
            AVIATION. By using or otherwise accessing the HeliTaxii application,
            you acknowledge that you accept the practices and policies outlined
            in this Privacy Statement.
          </p>
          <h3>WHAT PERSONAL INFORMATION DOES THUMBY AVIATION COLLECT?</h3>
          <p>We collect the following types of information from our users:</p>
          <ul className="policy-points">
            <li>
              Personal Information You Provide to Us:
              <br />
              We may receive and store any information you submit to the
              HeliTaxii application (or otherwise authorize us to obtain – such
              as, from (for example) your Facebook account). The types of
              personal information collected may include your full name, email
              address, gender, IP address, browser information, username,
              demographic information, and any other information necessary for
              us to provide the HeliTaxii application services.{" "}
            </li>
            <li>
              Personal Information Collected Automatically:
              <br />
              We receive and store certain types of usage related information
              whenever you interact with HeliTaxii application. For example,
              THUMBY AVIATION may automatically receive and record information
              regarding your computer’s IP address, browser information,
              Facebook user ID, Facebook Page fan status, and URLs accessed.
              Such information may be shared in aggregate (non-personally
              identifiable) form with our partners.{" "}
            </li>
          </ul>
          <h3>HOW DOES THUMBY AVIATION USE THE INFORMATION IT COLLECTS? </h3>
          <p>
            THUMBY AVIATION uses the information described in this Privacy
            Statement (i) internally, to analyze, develop and improve its
            products and services, and (ii) as set forth below in the “Will
            THUMBY AVIATION Share any of the personal information it Collects”
            section below.
          </p>
          <h3>APPLICATION PARTNER TREATMENT OF PERSONAL INFORMATION.</h3>
          <p>
            THUMBY AVIATION may provide personal information to the applicable
            Application Partner. The Application Partner’s use of your personal
            information is subject to the Application Partner’s separate privacy
            policy – and not this Privacy Statement. The Application Partner’s
            privacy policy is linked to from within the Partner’s Facebook
            application.{" "}
          </p>
          <h3>
            WILL THUMBY AVIATION SHARE ANY OF THE PERSONAL INFORMATION IT
            RECEIVES?
          </h3>
          <p>
            Personal information about our users is an integral part of our
            business. We neither rent nor sell your personal information to
            anyone (with the exception of sharing your information with an
            applicable Application Partner – see the “Application Partner
            Treatment” section above). We share your personal information only
            as described below.
          </p>
          <ul className="policy-points">
            <li>
              Application Partners:
              <p>
                We will share your personal information with an applicable
                Application Partner (see the “Application Partner Treatment”
                section above).
              </p>
            </li>
            <li>
              <p>
                Agents: We employ other companies and people to perform tasks on
                our behalf and need to share your information with them to
                provide products or services to you. Unless we tell you
                differently, THUMBY AVIATION’s agents do not have any right to
                use personal information we share with them beyond what is
                necessary to assist us. You hereby consent to our sharing of
                personal information for the above purposes.
              </p>
            </li>
            <li>
              Business Transfers:{" "}
              <p>
                In some cases, we may choose to buy or sell assets. In these
                types of transactions, customer information is typically one of
                the business assets that are transferred. Moreover, if THUMBY
                AVIATION, or substantially all of its assets were acquired, or
                in the unlikely event that THUMBY AVIATION stops the HeliTaxii
                business, user information would be one of the assets that is
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur, and that any acquirer of THUMBY
                AVIATION may continue to use your personal information as set
                forth in this policy.{" "}
              </p>
            </li>
            <li>
              Protection of THUMBY AVIATION and Others:{" "}
              <p>
                We may release personal information when we believe in good
                faith that release is necessary to comply with the law; enforce
                or apply our conditions of use and other agreements; or protect
                the rights, property, or safety of THUMBY AVIATION, our
                employees, our users, or others. This includes exchanging
                information with other companies and organizations for fraud
                protection and credit risk reduction.
              </p>
            </li>
            <li>
              With Your Consent:{" "}
              <p>
                Except as set forth above, you will be notified when your
                personal information may be shared with third parties, and will
                be able to prevent the sharing of this information.
              </p>
            </li>
          </ul>
          <h3>CONDITIONS OF USE.</h3>{" "}
          <p>
            If you decide to use or otherwise access the HeliTaxii application,
            your use/access and any possible dispute over privacy is subject to
            this Privacy Statement and our Terms of Use, including limitations
            on damages, arbitration of disputes, and application of California
            state law.
          </p>
          <h3>THIRD PARTY APPLICATIONS/WEBSITES.</h3>
          <p>
            The HeliTaxii application may permit you to link to other
            applications or websites. Such third party applications/websites are
            not under THUMBY AVIATION’s control, and such links do not
            constitute an endorsement by THUMBY AVIATION of those other
            applications/websites or the services offered through them. The
            privacy and security practices of such third party
            application/websites linked to the Application are not covered by
            this Privacy Statement, and THUMBY AVIATION is not responsible for
            the privacy or security practices or the content of such websites.
          </p>
          <h3>WHAT PERSONAL INFORMATION CAN I ACCESS?</h3>
          <p>
            THUMBY AVIATION allows you to access the following information about
            you for the purpose of viewing, and in certain situations, updating
            that information. This list may change in the event the HeliTaxii
            application changes. - Account and user profile information - User
            e-mail address, if applicable - Facebook profile information, if
            applicable - User preferences - Application specific data{" "}
          </p>
          <h3>CAN CHILDREN USE THE HELITAXII APPLICATION?</h3>
          <p>
            Our site and the services available through THUMBY AVIATION are not
            intended for children under the age of 13. THUMBY AVIATION does not
            knowingly or specifically collect information about children under
            the age of 13 and believes that children of any age should get their
            parents’ consent before giving out any personal information. We
            encourage you to participate in your child’s web experience.
          </p>
          <h3>CHANGES TO THIS PRIVACY STATEMENT.</h3>
          <p>
            THUMBY AVIATION may amend this Privacy Statement from time to time.
            Use of information we collect now is subject to the Privacy
            Statement in effect at the time such information is used. If we make
            changes in the way we use personal information, we will notify you
            by posting an announcement on our Site or sending you an email.
            Users are bound by any changes to the Privacy Statement when he or
            she uses or otherwise accesses the HeliTaxii application after such
            changes have been first posted.{" "}
          </p>
          <h3>QUESTIONS OR CONCERNS.</h3>
          <p>
            If you have any questions or concerns regarding privacy on our
            Website, please send us a detailed message at [COMPANY-EMAIL]. We
            will make every effort to resolve your concerns. Effective Date:
            February 5th, 2018
          </p>
        </div>
        <footer id="footer" className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 conditions" align="center">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <a href="/#top">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="https://thumbyaviation.com/#CONTACT">Contact Us</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">T&amp;C</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
              <div
                className="col-lg-12"
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  fontSize: "18px",
                }}
              >
                <b>© 2018 Thumby Aviation Pvt. Ltd - All Rights Reserved</b>
                <br />
                <p style={{ lineHeight: "30px", fontSize: "16px" }}>
                  Call : +91-9400399999
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default PrivacyPolicyComponent;
