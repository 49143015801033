import React, { Component } from "react";
import HeaderComponent from "./Header";
import "./Terms.css";
import "./grids-responsive-min.css";

class TermsComponent extends Component {
  render() {
    return (
      <div>
        <HeaderComponent />
        <div className="terms-section">
          <h2>CONDITIONS OF CARRIAGE</h2>
          <h3>General Terms and Conditions</h3>
          <ol className="policy-points bold-points">
            <li>
              <b>Bookings: </b>
              <br />
              Bookings can be done for a specific date and specific time slot
              and the ticket along with booking ID generated on receipt of full
              payment from customer constitutes prima facie evidence of the
              contract of carriage between Thumby Aviation’s subsidiary service
              HeliTaxii and the Customer named on the booking.
              <br />
              Bookings and payment can be done through the following :
              <ul className="policy-points">
                <li>
                  Using our free HeliTaxii app available on both Android and IOS
                </li>
                <li>
                  Through our offline counters at Bangalore Airport Arrival
                  Terminal and at e-city phase 1
                </li>
                <li>Travel partners</li>
              </ul>
            </li>
            <li>
              <b>Fare Rules:</b>
              <br />
              The fare includes the HeliTaxii ride and the pick-up/drop facility
              from/to the Thumby Helipad at Bangalore International Airport
              premise and to/from the Airport Terminals.
              <br />
              The charges are subject to change, but at any point of time will
              be standardized across all points of sale. The charges are
              inclusive of applicable taxes, viz. 18% GST.
              <br />
              Baggage restrictions are applicable, please refer to the clause on
              baggage rules for details. Any excess baggage is chargeable on
              terms as cited below:
            </li>
            <li>
              <b>Baggage Rules:</b>
              <ul className="policy-points">
                <li>
                  <b>Baggage Allowance</b> – Up to 10 Kgs of cabin luggage with
                  a maximum allowed dimension of 50cm (length) X 35cm (Width) X
                  20cm (Height) and one hand baggage/laptop bag not exceeding 5
                  Kgs.
                </li>
                <li>
                  <b> Items which are Unacceptable to carry on board:</b>
                  <br />
                  Thumby Aviation reserves the right to refuse carriage of items
                  flagged as prohibited goods as per company policy. Please find
                  below list of prohibited items:
                  <ol className="policy-points">
                    <li>
                      Items which are listed as dangerous to carry on board as
                      per International Civil Aviation Organization and/or
                      Indian Director General of Civil Aviation technical
                      instructions on Safe transport of Dangerous goods by Air
                    </li>
                    <li>
                      Any item marked as dangerous goods to carry on board as
                      per the company operation manual
                    </li>
                    <li>
                      Compressed gases: deeply refrigerated, flammable,
                      non-flammable and poisonous such as butane oxygen, liquid
                      nitrogen, aqualung cylinders and compressed gas cylinders
                    </li>
                    <li>
                      Corrosives items such as acids, alkalis, mercury and wet
                      cell batteries and apparatus containing mercury;
                    </li>
                    <li>
                      Explosives, munitions, fireworks and flares, ammunition
                      including blank cartridges handguns, fireworks, pistol
                      caps, swords, knives and similar items
                    </li>
                    <li>
                      Flammable liquids and solids such as lighter refills,
                      lighter fuel, matches, paints, thinners, fire-lighters,
                      lighters that need inverting before ignition, matches,
                      radioactive material, briefcases with installed alarm
                      devices;
                    </li>
                    <li>Live or dead animals</li>
                    <li>Human or animal remains</li>
                    <li>
                      Items, which, are unsuitable for carriage by reason of
                      their weight, shape, size or character{" "}
                    </li>
                    <li>
                      Poisons and infectious substances such as insecticides,
                      weed-killers and live virus materials
                    </li>
                    <li>Contraband items</li>
                    <li>
                      Any other item that Thumby Aviation feels may cause
                      inconvenience or endanger the customers{" "}
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Right to refuse carriage of baggage</b>
                  <br />
                  Thumby Aviation may refuse to carry as Baggage any item
                  reasonably considered by the airline to be unsuitable for
                  carriage because of its size, shape, weight, content, being
                  radioactive, or character, or for safety or operational
                  reasons, or in the interests of the comfort of other
                  Customers.
                </li>
                <li>
                  <b>Right to search</b>
                  <br />
                  Thumby Aviation Security Personnel reserve the right to
                  search/frisk baggage through metal detector and / or manual
                  search. If a Customer refuses to comply with such searches or
                  scans, we reserve the right to refuse carriage to such
                  Customer and his/her Baggage without refund of fare and
                  without any other liability to such Customer
                </li>
              </ul>
            </li>
            <li>
              <b>Delay and or Cancellation of HeliTaxii Ride</b> <br />
              Thumby Aviation strives to ensure there is no delay in flying its
              Customers and their Baggage. We try our best to adhere to the
              chosen slot timings in effect on the date of travel. However,
              owing to unanticipated situations including bad weather, we may
              encounter a rare occasion of HeliTaxii ride delay and/or
              cancellation.
              <br />
              If a HeliTaxii flight is cancelled or delayed by more than 45
              minutes (for helitaxii booked to Bangalore Airport), a Customer
              shall have to right to choose a refund; or a credit for future
              travel on HeliTaxii; or re-booking onto an alternative HeliTaxii
              flight at no additional cost (subject to availability).
              <br />
              In case customer wants to cancel the HeliTaxii ride post booking,
              50% cancellation fees will be charged up to 48 Hours prior to
              HeliTaxii Slot Start time. For any cancellation later than 48
              hours of HeliTaxii slot start time, Zero refund is provided.
            </li>
            <li>
              <b>Refund</b>
              <br />
              Refunds against the residual value after deduction of the
              applicable fee will be made available as per the following:
              <ul className="policy-points">
                <li>
                  For Bookings made through credit/debit cards, the refund will
                  be processed back to the credit/debit card.
                </li>
                <li>
                  For Bookings made through net banking, the refund will be
                  processed into the same bank account.
                </li>
                <li>
                  For Bookings made through Travel Partners or online travel
                  portals, the refund may be claimed from the respective travel
                  agents/ portals.
                </li>
                <li>
                  For Bookings made by cash at the HeliTaxii counter, the refund
                  will be processed at the respective counter.
                </li>
              </ul>
            </li>
            <li>
              <b>Death or Injury to Customers:</b>
              <br />
              In the event of death or any other bodily injury suffered by a
              Customer during the course of carriage by air on a HeliTaxii ride,
              Thumby Aviation’s liability will be governed by the relevant
              provisions of the Carriage by Air Act, 1972 and any modifications
              etc. The company covers insurance for loss of life, bodily injury
              and loss of baggage in case of an eventuality as per the
              guidelines provided by Ministry of Civil Aviation, DGCA.
            </li>
          </ol>
        </div>
        <footer id="footer" className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 conditions" align="center">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <a href="/#top">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  {/* <li>
                    <a href="">FAQ's</a>
                  </li> */}
                  <li>
                    <a href="https://thumbyaviation.com/#CONTACT">Contact Us</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">T&amp;C</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
              <div
                className="col-lg-12"
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  fontSize: "18px",
                }}
              >
                <b>© 2018 Thumby Aviation Pvt. Ltd - All Rights Reserved</b>
                <br />
                <p style={{ lineHeight: "30px", fontSize: "16px" }}>
                  Call : +91-9400399999
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default TermsComponent;
