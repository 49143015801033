import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class DialogSuccess extends React.Component {
  navigateToJoyRide = paymentlink => {
    window.open(paymentlink);
    window.location.href = "/joy-rides";
  };

  render() {
    const { open, data, block } = this.props;
    const { bookingId, paymentlink } = data;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ minWidth: "400px" }}>
          <span style={{ color: "green" }}>Booking Succesful</span>
          <span> (Booking ID : {bookingId})</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {block
              ? "Please complete the payment in the next 10 minutes."
              : "We have confirmed your seats for the next 10 minutes."}
            <br />
            Proceed to complete payment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.navigateToJoyRide(paymentlink)}
            color="primary"
          >
            Proceed to Payment Gateway
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogSuccess;
