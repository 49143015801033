import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const LoadingWithBackdrop = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "100px",
      }}
    >
      <CircularProgress />
    </div>
  );
};
