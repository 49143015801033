import React, { Component } from "react";
import "./App.css";
import "./blaze.css";
import "./pure.css";
import "./grids-responsive-min.css";
import Mail from "material-ui/svg-icons/communication/mail-outline";
import Phone from "material-ui/svg-icons/communication/phone";

class FooterComponent extends Component {
  render() {
    return (
      <footer
        id="footer"
        className="footer-section"
        style={{
          borderTop: "1px solid #ccc",
          paddingTop: "60px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 conditions" align="center">
              <ul
                style={{
                  listStyle: "none",
                  background: "transparent !important",
                }}
              >
                <li>
                  <a href="/about-us">About Us</a>
                </li>

                <li>
                  <a href="https://thumbyaviation.com/#CONTACT">Contact Us</a>
                </li>
                <li>
                  <a href="/terms-and-conditions">T&amp;C</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
              <div className="clearfix" />
            </div>
            <div
              className="col-lg-12"
              style={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                lineHeight: "30px",
                fontSize: "16px",
              }}
            >
              <p>© 2024 Thumby Aviation Pvt. Ltd - All Rights Reserved</p>
              <p>
                <Mail />{" "}
                <span style={{ verticalAlign: "text-bottom" }}>
                  info@helitaxii.com
                </span>
              </p>
              <p>
                <Phone />{" "}
                <span style={{ verticalAlign: "text-bottom" }}>
                  +91-9400399999
                </span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;
