import React, { Component } from "react";
import FooterComponent from "../../components/FooterComponent";

import "./index.css";

class ChardhamByHelicopter extends Component {
  render() {
    return (
      <div id="chardham">
        <div className="chardhamWrapper">
          <video
            className="charDhamVideo"
            src="https://d2wedyshsbbj5x.cloudfront.net/thumby-video.mp4"
            autoPlay
            playsInline
            loop
            muted
            style={{
              objectFit: "cover",
              width: "calc(100% + 80px)",
              marginLeft: "-40px",
              marginRight: "-40px",
            }}
          ></video>
          <h1
            style={{
              marginTop: "12px",
              fontFamily: "Montserrat",
              fontWeight: 700,
              lineHeight: "48px",
              textAlign: "center",
            }}
          >
            Chardham by Helicopter
          </h1>
          <h2
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "32px",
            }}
          >
            Fly to bliss with Thumby Charters: Chardham heli-tour
          </h2>
          <p>
            Thumby Aviation is now live with its charter services to the sacred{" "}
            <a
              href="https://thumbyaviation.com/chardham-by-helicopter"
              target="_blank"
              style={{
                fontSize: "inherit",
              }}
            >
              Chardham Yatra by helicopter
            </a>
            . With our exclusive and customizable{" "}
            <a
              href="https://thumbyaviation.com/chardham-by-helicopter/packages"
              target="_blank"
              style={{
                fontSize: "inherit",
              }}
            >
              packages
            </a>
            , we ensure utmost satisfaction of devotees in the pilgrimage yatra
            corroborating utmost comfort, luxury, time-efficiency and much more.
          </p>
          <p>
            <a
              href="https://thumbyaviation.com/chardham-by-helicopter"
              target="_blank"
              style={{
                fontSize: "inherit",
              }}
            >
              Chardham by helicopter
            </a>{" "}
            will start from Dehradun, soar through the himalayas taking you to
            the 4 holy shrines at Yamunotri, Gangotri, Kedarnath and Badrinath.
            The packages include luxurious accommodation, meal provisions, VIP
            darshan, road transport and much more. There are other packages to{" "}
            <a
              href="https://thumbyaviation.com/chardham-by-helicopter/packages/do-dham"
              target="_blank"
              style={{
                fontSize: "inherit",
              }}
            >
              Do Dham by helicopter
            </a>{" "}
            and{" "}
            <a
              href="https://thumbyaviation.com/chardham-by-helicopter/packages/ek-dham"
              target="_blank"
              style={{
                fontSize: "inherit",
              }}
            >
              Ek Dham by Helicopter
            </a>
          </p>
        </div>
        <div
          className="buttonWrapper"
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "20px",
          }}
        >
          <a
            href="https://thumbyaviation.com/chardham-by-helicopter?contactUs=true&enquiryType=Chardham%20Yatra%205N/6D%20Package"
            target="_blank"
          >
            <button
              className="btn btn-primary"
              style={{ width: "200px", marginRight: "10px" }}
            >
              Book Now
            </button>
          </a>
          <a
            href="https://thumbyaviation.com/chardham-by-helicopter"
            target="_blank"
          >
            <button className="btn btn-primary" style={{ width: "200px" }}>
              Know More
            </button>
          </a>
        </div>

        <FooterComponent />
      </div>
    );
  }
}

export default ChardhamByHelicopter;
