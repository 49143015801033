import React, { useEffect, Fragment, useState, useCallback } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import FooterComponent from "../../components/FooterComponent";
import { Loading } from "../../components/Loading";

const Card = ({
  id,
  badgeText,
  subTitle,
  mainTitle,
  time,
  price,
  priceType,
  style = {},
  bookNowLink = (
    <NavLink to="/bookwithus">
      <button className="desktop-button">Book Now</button>
    </NavLink>
  ),
}) => {
  return (
    <div className="helitaxii_card" style={style}>
      <div className="card_badge">{badgeText}</div>
      <div className="card_subTitle">{subTitle}</div>
      <div className="card_title">{mainTitle}</div>
      <div className="card_passengers">{time}</div>
      <div className="card_price">₹{price.toLocaleString("en-IN")}/-</div>
      <div className="card_price_type">{priceType}</div>
      <div className="buttonWrapper">{bookNowLink}</div>
    </div>
  );
};

export default () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPackages = useCallback(() => {
    setPackages([
      {
        id: 1,
        title: "Bharmour to Gauri Kund",
        subTitle: (
          <span>
            22<sup>nd</sup>Aug - 11<sup>th</sup> Sep 2024
          </span>
        ),
        time: "Shuttle services from Bharmour to GauriKund and return",
        badgeText: "Shuttle",
        priceType: "Per seat",
        price: 7750,
      },
      {
        id: 2,
        title: "Chamba to Bharmour",
        subTitle: (
          <span>
            22<sup>nd</sup>Aug - 11<sup>th</sup> Sep 2024
          </span>
        ),
        time: "Chamba to Bharmour (round trip) with shuttle services from Bharmour to GauriKund and return included",
        badgeText: "Charter",
        priceType: "Per seat",
        price: 57750,
      },
    ]);
    setLoading(false);
  }, []);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  return (
    <Fragment>
      <div className="homeWrapper">
        <div className="cardHolder">
          <div className="titleOne">
            From 22<sup>nd</sup> Aug to 11<sup>th</sup> Sep
          </div>
          <div className="titleTwo">Shri Mani Mahesh Yatra 2024</div>
          <div className="titleThree">
            Brand new <b>Airbus H125</b> Helicopter 2022 model
          </div>
          <div className="cardsWrapper">
            {loading && <Loading />}
            {packages.map((p) => (
              <Card
                key={p.id}
                id={p.id}
                badgeText={p.badgeText}
                subTitle={p.subTitle}
                mainTitle={p.title}
                time={p.time}
                price={p.price}
                priceType={p.priceType}
              />
            ))}
          </div>
        </div>
        <div className="imageHolder"></div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
