import React, { Component } from "react";
import "../../components/Home.css";
class AirPortShuttleComponent extends Component {
  render() {
    return (
      <div>
        <section id="airport-shuttle" className="home-jumbotron">
          <h3>Airport Shuttle</h3>
          <div className="bookings-wrapper">
            <p>Time to breathe easy!</p>
            <p
              className="description"
              style={{ fontSize: "20px", lineHeight: "30px" }}
            >
              The dreadful traffic-ridden red lines on the maps are a story of
              the past! The journey by road to Bangalore International Airport
              which often involves up to 3 hours in rush hour traffic, can now
              be replaced by a blissful 15 minutes ride with a view of the
              beautiful City of Flowers with India’s first dedicated helitaxii
              service.
              <br />
              <br />
              For further queries <br />
              <i class="fas fa-phone-square" />
              &nbsp;&nbsp;+91-9400399999
              <br />
              <i class="fas fa-envelope" />
              &nbsp;&nbsp;info@helitaxii.com
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default AirPortShuttleComponent;
