const getConfig = () => {
  if (process.env.REACT_APP_ENV === "dev") {
    return {
      baseUrl: "http://localhost:8080/",
      firebaseConfig: {
        apiKey: "AIzaSyBsO3j9hCtkTFnFYJFAwWpqoUzocfdvMow",
        authDomain: "helitaxii-dev.firebaseapp.com",
        projectId: "helitaxii-dev",
        storageBucket: "helitaxii-dev.appspot.com",
        messagingSenderId: "842170867801",
        appId: "1:842170867801:web:521cc56fc5cc2b7e335148",
      },
    };
  }

  if (process.env.REACT_APP_ENV === "staging") {
    return {
      baseUrl: "https://test.helitaxii.com/",
      firebaseConfig: {
        apiKey: "AIzaSyBsO3j9hCtkTFnFYJFAwWpqoUzocfdvMow",
        authDomain: "helitaxii-dev.firebaseapp.com",
        projectId: "helitaxii-dev",
        storageBucket: "helitaxii-dev.appspot.com",
        messagingSenderId: "842170867801",
        appId: "1:842170867801:web:521cc56fc5cc2b7e335148",
      },
    };
  }

  if (process.env.REACT_APP_ENV === "production") {
    return {
      baseUrl: "https://helitaxii-api.helitaxii.com/",
      firebaseConfig: {},
    };
  }
};

export default getConfig();
